'use client'
"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  Actions: () => Actions,
  SPECIALITIES: () => SPECIALITIES,
  VERTICALS: () => VERTICALS
});
module.exports = __toCommonJS(src_exports);

// src/speciality.ts
var SPECIALITIES = [
  "gyn",
  "hud",
  "pediatrician",
  "fysio",
  "kiro",
  "midwife",
  "PT",
  "plastikk"
];

// src/tracking.ts
var Actions = /* @__PURE__ */ ((Actions2) => {
  Actions2["SelectVertical"] = "select_vertical_v2";
  Actions2["SelectSpeciality"] = "select_speciality_v2";
  Actions2["SelectLocation"] = "select_location_v2";
  Actions2["SelectClinic"] = "select_clinic_v2";
  Actions2["SelectPractitioner"] = "select_practitioner_v2";
  Actions2["SkipSelectPractitioner"] = "skip_select_practitioner_v2";
  Actions2["OpenPractitionerInfo"] = "open_practitioner_info";
  Actions2["OpenMap"] = "open_map_v2";
  Actions2["SelectTime"] = "select_time_v2";
  Actions2["Permissions"] = "permissions_v2";
  Actions2["SelectPersonalDetails"] = "select_personal_details_v2";
  Actions2["Confirm"] = "confirm_booking_v2";
  Actions2["CompletedBooking"] = "completed_booking_v2";
  Actions2["ClickedBookAgain"] = "clicked_book_again_v2";
  Actions2["SelectDateButtonDisabled"] = "select_date_button_disabled";
  Actions2["ClickAppStore"] = "click_app_store";
  Actions2["ClickPlayStore"] = "click_play_store";
  Actions2["ClickNextAvailable"] = "click_next_available";
  Actions2["ExitFlow"] = "exit_flow_v2";
  Actions2["GoBack"] = "go_back_v2";
  Actions2["AddPartner"] = "add_partner_v2";
  Actions2["BookAppointmentError"] = "book_appointment_error_v2";
  Actions2["ClinicSortOptionClicked"] = "option_clicked_v2";
  Actions2["CountryCodeTrimmed"] = "country_code_trimmed";
  Actions2["CountryCodePicked"] = "country_code_picked_v2";
  Actions2["CountryCodePressed"] = "country_code_pressed_v2";
  Actions2["DeleteCard"] = "delete_card_v2";
  Actions2["SelectAgreementClicked"] = "select_agreement_clicked";
  Actions2["SelectAgreementSuccess"] = "select_agreement_success";
  Actions2["WithoutAgreementClicked"] = "without_agreement_clicked";
  Actions2["ChangeAgreementClicked"] = "change_agreement_clicked";
  Actions2["ModalChangeAgreementClicked"] = "modal_change_agreement_clicked";
  Actions2["ModalWithoutAgreementClicked"] = "modal_without_agreement_clicked";
  Actions2["ModalCancelAppointmentClicked"] = "modal_cancel_appointment_clicked";
  Actions2["SelectCountryClicked"] = "select_country_clicked";
  Actions2["SearchCountryClicked"] = "search_country_clicked";
  Actions2["SelectCountrySuccess"] = "select_country_success";
  Actions2["SelectCountryInvalid"] = "select_country_invalid";
  Actions2["ChangeCountryClicked"] = "change_country_clicked";
  Actions2["InitializePayment"] = "initialize_payment_v2";
  Actions2["InitializedPayment"] = "initialized_payment_v2";
  Actions2["InitializePaymentError"] = "initialize_payment_error_v2";
  Actions2["PaymentCompleted"] = "payment_completed_v2";
  Actions2["PaymentError"] = "payment_error_v2";
  Actions2["VippsRedirectInitialized"] = "vipps_redirect_initialized_v2";
  Actions2["VippsRedirectFailed"] = "vipps_redirect_failed_v2";
  Actions2["VippsPaymentCancelled"] = "vipps_payment_cancelled_v2";
  Actions2["VippsPaymentCompleted"] = "vipps_payment_completed_v2";
  Actions2["UpdateUserPhone"] = "update_user_phone_v2";
  Actions2["VideoInfoClicked"] = "video_info_clicked_v2";
  Actions2["NewsletterAccept"] = "newsletter_accepted";
  Actions2["NewsletterReject"] = "newsletter_rejected";
  Actions2["NewsletterMissing"] = "newsletter_email_missing";
  Actions2["NewsletterSubmitted"] = "newsletter_email_submitted";
  Actions2["CreateMembershipError"] = "create_membership_error";
  Actions2["HomeAddPartners"] = "home_insurance&discount_add";
  Actions2["HomeAddPartnersSelected"] = "home_insurance&discount_add_selected";
  Actions2["HomeAddPartnersSelectedAdded"] = "home_insurance&discount_add_selected_added";
  Actions2["HomeAddPartnersSelectedError"] = "home_insurance&discount_add_selected_error";
  Actions2["HomeAddPartnersSelectedRequest"] = "home_insurance&discount_add_selected_request";
  Actions2["HomeContact"] = "home_contact";
  Actions2["HomeOnboarding"] = "home_onboarding";
  Actions2["HomePartnersExisting"] = "home_insurance&discount_existing";
  Actions2["HomePartnersExistingError"] = "home_insurance&discount_existing_error";
  Actions2["HomePartnersExistingRemove"] = "home_insurance&discount_existing_remove";
  Actions2["HomePartnersExistingRequestUpdate"] = "home_insurance&discount_existing_request_update";
  Actions2["HomePartnersExistingUpdated"] = "home_insurance&discount_existing_updated";
  Actions2["HomeReturn"] = "home_return";
  Actions2["HomeScreen"] = "home_screen";
  Actions2["HomeVideoHelp"] = "home_video_help";
  Actions2["UpdateAppShown"] = "update_app_shown";
  Actions2["UpdateAppPressed"] = "update_app_pressed";
  Actions2["ExpiredMembershipRemoved"] = "expired_membership_removed";
  Actions2["ExpiredMembershipRenewed"] = "expired_membership_renewed";
  Actions2["LoginButtonPressed"] = "login_button_pressed";
  Actions2["LoginCancelButtonPressed"] = "login_cancel_button_pressed";
  Actions2["LoginCompleted"] = "login_completed";
  Actions2["LoginError"] = "login_error";
  Actions2["LoginFinished"] = "login_finished";
  Actions2["LoginSetPinCode"] = "login_setting_pin_code";
  Actions2["LogoutCompleted"] = "logout_completed";
  Actions2["LogoutError"] = "logout_error";
  Actions2["LogoutInitiated"] = "logout_initiated";
  Actions2["LogoutProceed"] = "logout_proceed";
  Actions2["LogoutPromptAnswered"] = "logout_prompt_answered";
  Actions2["ClickExorLive"] = "click_exor_live";
  Actions2["GoToExorLive"] = "go_to_exor_live";
  Actions2["CancelExorLive"] = "cancel_exor_live";
  Actions2["LoginForgotPin"] = "login_forgot_pin";
  Actions2["LoginPinAndBiometricsSuccess"] = "login_pin_and_biometrics_success";
  Actions2["MotimateCreateUserStart"] = "motimate_create_user_start";
  Actions2["MotimateCreateUserSubmit"] = "motimate_create_user_submit";
  Actions2["MotimateCreateUserError"] = "motimate_create_user_error";
  Actions2["MotimateCreateUserCompleted"] = "motimate_create_user_completed";
  Actions2["MotimateCreateUserCanceled"] = "motimate_create_user_canceled";
  Actions2["MotimateLinkClicked"] = "motimate_link_clicked";
  Actions2["PermissionRequested"] = "permission_requested";
  Actions2["PermissionsGranted"] = "permissions_granted";
  Actions2["PermissionsMissing"] = "permissions_missing";
  Actions2["PermissionsOverlay"] = "permissions_overlay";
  Actions2["PermissionsRedirectToSettings"] = "permissions_open_settings";
  Actions2["PermissionsRedirectToSettingsFailed"] = "permissions_open_settings_failed";
  Actions2["ProfileBiometrics"] = "profile_pin&biometrics";
  Actions2["ProfileBiometricsChangePinCode"] = "profile_pin&biometrics_pincode";
  Actions2["ProfileBiometricsSetBiometrics"] = "profile_pin&biometrics_biometrics_activate";
  Actions2["ProfileBiometricsUnsetBiometrics"] = "profile_pin&biometrics_biometrics_deactivate";
  Actions2["ProfileContact"] = "profile_contact";
  Actions2["ProfileDetailsCountryCodePicked"] = "profile_personal_information_country_code_picked";
  Actions2["ProfileDetailsCountryCodePressed"] = "profile_personal_information_country_code_pressed";
  Actions2["ProfileInfoSaveError"] = "Profile_Save_Error";
  Actions2["ProfileInfoSaved"] = "Saved_Personal_Info";
  Actions2["ProfileOnboarding"] = "profile_onboarding";
  Actions2["ProfilePartners"] = "home_insurance&discount";
  Actions2["ProfilePaymentCard"] = "profile_payment_card";
  Actions2["ProfilePermissions"] = "profile_permissions";
  Actions2["ProfileReturn"] = "profile_return";
  Actions2["ProfileSavedDetails"] = "profile_personal_information_saved";
  Actions2["ProfileScreen"] = "profile_screen";
  Actions2["ProfileVideoHelp"] = "profile_video_help";
  Actions2["VersionDeprecationWarning"] = "version_deprecation_warning";
  Actions2["VersionDeprecationWarningAction"] = "version_deprecation_warning_action";
  Actions2["VideoAnsweredCall"] = "video_answered_call";
  Actions2["VideoAnsweredCallOld"] = "Answered_Video_Call";
  Actions2["VideoCallEnded"] = "video_call_ended";
  Actions2["VideoConnected"] = "video_connected";
  Actions2["VideoConnecting"] = "video_connecting";
  Actions2["VideoDeclined"] = "video_declined";
  Actions2["VideoDisconnected"] = "video_disconnected";
  Actions2["VideoEndedConsultationOld"] = "Ended_Video_Consultation";
  Actions2["VideoEnteredScreen"] = "video_entered_screen";
  Actions2["VideoError"] = "video_error";
  Actions2["VideoReceivedCall"] = "video_received_call";
  Actions2["VideoRecievedCallOld"] = "Recieved_Video_Call";
  Actions2["VideoSettingsChangeMicrophone"] = "video_settings_change_microphone";
  Actions2["VideoSettingsFetchingActiveAudio"] = "video_settings_fetching_active_audio";
  Actions2["VideoSettingsFlipCamera"] = "video_settings_flip_camera";
  Actions2["VideoSettingsToggleMuteMicrophone"] = "video_settings_toggle_mute_microphone";
  Actions2["VideoSummaryDisplayed"] = "video_displayed_summary";
  Actions2["VideoTryingToConnect"] = "video_trying_to_connect";
  Actions2["ApiError"] = "Api_Error";
  Actions2["AppError"] = "app_error";
  Actions2["BookInClinic"] = "book_in_clinic";
  Actions2["ClickedChooseMedicalIssue"] = "clicked_choose_medical_issue";
  Actions2["SelectMedicalIssue"] = "select_medical_issue";
  Actions2["ChangeMedicalIssue"] = "change_medical_issue";
  Actions2["ClickedReadMoreVideo"] = "clicked_read_more_video";
  Actions2["ClickedClosedInfoCard"] = "clicked_closed_info_card";
  Actions2["MedicalInfoCardTreatable"] = "medical_info_card_treatable";
  Actions2["MedicalInfoCardClickedContinueVideo"] = "medical_info_card_clicked_continue_video";
  Actions2["MedicalInfoCardClickedBookClinic"] = "medical_info_card_clicked_book_clinic";
  Actions2["ClickedAddChild"] = "clicked_add_child";
  Actions2["AddedChild"] = "add_child_success";
  Actions2["AddChildClosed"] = "add_child_closed";
  Actions2["ErrorAddingChild"] = "add_child_error";
  Actions2["SelectedChild"] = "select_child";
  Actions2["SelectedSelf"] = "select_myself";
  Actions2["DeletedChild"] = "deleted_child";
  Actions2["ClickedPhysicalGuide"] = "clicked_physical_guide";
  Actions2["CloseVideoModal"] = "close_video_modal";
  Actions2["OpenVideoModal"] = "open_video_modal";
  Actions2["FutureDateError"] = "web_booking_profilePersonalInformation_futureDateError";
  Actions2["PastDateError"] = "web_booking_profilePersonalInformation_pastDateError";
  Actions2["PhoneVerificationError"] = "phoneVerificationError";
  Actions2["PhoneVerificationSuccess"] = "phoneVerificationSuccess";
  Actions2["PhoneVerificationSupport"] = "phoneVerificationSupport";
  return Actions2;
})(Actions || {});

// src/verticals.ts
var VERTICALS = [
  "covid",
  "dermatologist",
  "general",
  "mental-health",
  "ear-nose-throat",
  "eye-doctor",
  "gynecologist",
  "physical",
  "psychology",
  "specialist"
];
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Actions,
  SPECIALITIES,
  VERTICALS
});
