import '../config/apiClient';

import { Clinics, Services } from '@booking/api';
import {
  getVertical,
  useClinicStore,
  useNavigationStore,
  useServiceTreeStore,
} from '@booking/shared';
import { Clinic, Locales, ServiceTree, Vertical } from '@booking/types';
import { ModalProvider } from '@booking/ui-web';
import BookingPageWrap from '@layouts/BookingPageWrap';
import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query';
import Steps from 'layouts/BookingSteps';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { shallow } from 'zustand/shallow';

export default function Page() {
  const { setServiceTree } = useServiceTreeStore((state) => ({
    setServiceTree: state.setServiceTree,
  }));
  const setClinics = useClinicStore()((state) => state.setClinics);
  const { setQueries, queries } = useNavigationStore(
    (state) => ({
      setQueries: state.setQueries,
      queries: state.queries,
    }),
    shallow,
  );

  const { partner, partnerId } = queries;

  useQuery(
    ['service-tree', partnerId, partner],
    () => Services.getServiceTree({ partner, partnerId }),
    {
      onSuccess: (data: ServiceTree[]) => {
        setServiceTree(data);
        setQueries(router.query);
      },
      refetchOnWindowFocus: false,
    },
  );

  useQuery(['clinics'], ({ signal }) => Clinics.getClinics({ signal }), {
    onSuccess: (data: Clinic[]) => {
      setClinics(data);
    },
  });

  const router = useRouter();

  const { t } = useTranslation('booking');
  const vertical = getVertical(router.asPath);

  // Hack for iOS to set color of status bar
  // to be the same as the vertical theme currently selected.
  // Used in the meta content 'theme-color'
  const verticalColors: Record<Vertical, string> = {
    general: '#2e4f4e',
    covid: '#2e4f4e',
    specialist: '#4d4557',
    physical: '#1d4865',
    psychology: '#b4561f',
    'ear-nose-throat': '#4d4557',
    'eye-doctor': '#4d4557',
    'mental-health': '#b4561f',
    dermatologist: '#4d4557',
    gynecologist: '#4d4557',
  };

  return (
    <>
      <Head>
        <title>{t('seo.title')}</title>
        <meta name="description" content={t('seo.meta_description')} />

        <meta name="theme-color" content={verticalColors[vertical]} />
      </Head>

      <ModalProvider>
        <BookingPageWrap>
          <Steps router={router} />
        </BookingPageWrap>
      </ModalProvider>
    </>
  );
}

type StaticProps = {
  locale: Locales;
} & GetStaticProps;

export async function getStaticProps({ locale }: StaticProps) {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(['service-tree'], () =>
    Services.getServiceTree({}),
  );
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'booking',
        'partners',
      ])),
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 600,
  };
}
